import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import * as actions from "../../store/actions/actions";
import { possibleVariables, possibleStatus } from "../../utils/parameters";

import Periods from "../Periods/Periods";
import { DatePicker } from "antd";

import "./Menu.css";

const Menu = props => {
  let menuElement = <div>Loading...</div>;
  if (props.dataFetched && !props.noData) {
    // We need to know the index of the selected period
    const indexActive = props.periods[props.selectedDate].periods.indexOf(
      props.selectedPeriod
    );

    // Variable
    const currentVariables =
      props.periods[props.selectedDate].variables[indexActive];

    const optionsVariables = possibleVariables.map(k => {
      let option;
      if (currentVariables[k.code]) {
        option = (
          <option key={k.code} value={k.code}>
            {k.name}
          </option>
        );
      }
      return option;
    });

    const variablesElement = (
      <div className="menu-element">
        <label>Variable</label>
        <select
          className="select-css"
          onChange={props.onChangeVariable}
          value={props.selectedVariable}
        >
          {optionsVariables}
        </select>
      </div>
    );

    // Status
    const optionsStatus = possibleStatus.map(v => <option key={v}>{v}</option>);

    const statusElement = (
      <div className="menu-element">
        <label>Status</label>
        <select
          className="select-css"
          onChange={props.onChangeStatus}
          value={props.selectedStatus}
        >
          {optionsStatus}
        </select>
      </div>
    );

    // Date
    const disabledDate = current => {
      return (
        current &&
        !props.dates.includes(moment.utc(current).format("YYYY-MM-DD"))
      );
    };
    const calendarElement = (
      <div className="menu-element">
        <div>Date</div>
        <DatePicker
          size="small"
          value={moment.utc(props.selectedDate, "YYYY-MM-DD")}
          disabledDate={disabledDate}
          onChange={props.onChangeDate}
          allowClear={false}
        />
      </div>
    );

    // Period
    const periodButtons = (
      <div className="menu-element">
        <Periods availablePeriods={props.periods[props.selectedDate].periods} />
      </div>
    );

    menuElement = (
      <>
        {variablesElement}
        {statusElement}
        {calendarElement}
        {periodButtons}
      </>
    );
  }

  if (props.noData) {
    menuElement = <div>THERE IS NO DATA</div>;
  }
  return <div className="menu">{menuElement}</div>;
};

const mapStateToProps = state => {
  return {
    periods: state.periods,
    dates: state.dates,
    selectedDate: state.selectedDate,
    selectedPeriod: state.selectedPeriod,
    selectedVariable: state.selectedVariable,
    dataFetched: state.dataFetched,
    noData: state.noData
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeDate: dateObject => {
      return dispatch((_, getState) => {
        const dateString = moment.utc(dateObject).format("YYYY-MM-DD");
        dispatch(
          actions.setProperty({
            key: "selectedDate",
            value: dateString
          })
        );

        let currentState = getState();
        // If the previous period is not available for the new date
        // change it to the only one that is available
        if (
          !currentState.periods[dateString].periods.includes(
            currentState.selectedPeriod
          )
        ) {
          dispatch(
            actions.setProperty({
              key: "selectedPeriod",
              value: currentState.periods[dateString].periods[0]
            })
          );
        }
        currentState = getState();

        // get the index of the selected period
        const indexPeriod = currentState.periods[dateString].periods.indexOf(
          currentState.selectedPeriod
        );

        // If the previous variable is not available for the new date/period
        // change it the first one that is available

        if (
          !currentState.periods[dateString].variables[indexPeriod][
            currentState.selectedVariable
          ]
        ) {
          for (const variable in currentState.periods[dateString].variables[
            indexPeriod
          ]) {
            if (
              currentState.periods[dateString].variables[indexPeriod][variable]
            ) {
              console.log(`Setting the new variable to ${variable}`);
              dispatch(
                actions.setProperty({
                  key: "selectedVariable",
                  value: variable
                })
              );
              break;
            }
          }
        }

        dispatch(
          actions.setProperty({
            key: "selectedStationId",
            value: null
          })
        );
        // clear the search box
        dispatch(
          actions.setProperty({
            key: "searchedStation",
            value: ""
          })
        );
      });
    },
    onChangeVariable: event => {
      return dispatch(() => {
        dispatch(
          actions.setProperty({
            key: "selectedVariable",
            value: event.target.value
          })
        );
        dispatch(
          actions.setProperty({ key: "selectedStationId", value: null })
        );

        // clear the search box
        dispatch(
          actions.setProperty({
            key: "searchedStation",
            value: ""
          })
        );
      });
    },
    onChangeStatus: event => {
      return dispatch(() => {
        dispatch(
          actions.setProperty({
            key: "selectedStatus",
            value: event.target.value.toLowerCase()
          })
        );
        dispatch(
          actions.setProperty({
            key: "selectedStationId",
            value: null
          })
        );
        // clear the search box
        dispatch(
          actions.setProperty({
            key: "searchedStation",
            value: ""
          })
        );
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
