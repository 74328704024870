import React from "react";
// import { connect } from "react-redux";
// import * as actions from "../../store/actions/actions";

import "./SearchResults.css";

const SearchResults = props => {
  let stationsList;

  if (props.elements.length === 0) {
    stationsList = <div />;
  } else {
    const listElements = props.elements.map((suggestion, index) => {
      let className;

      return (
        <li className={className} key={suggestion} onClick={props.onClick}>
          {suggestion}
        </li>
      );
    });

    stationsList = <ul className="suggestions">{listElements}</ul>;
  }

  return <div className="container-search-result">{stationsList}</div>;
};

export default SearchResults;
