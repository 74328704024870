export const possibleVariables = [
  { code: "PS", name: "Surface pressure", unit: "Pa" },
  { code: "T2M", name: "2 M temperature", unit: "K" },
  { code: "SAL", name: "Salinity", unit: " PSU" },
  { code: "SNOW", name: "Snow", unit: "m" },
  { code: "Q", name: "Specific humidity", unit: "Kg/kg" },
  { code: "RH2M", name: "2 M relative humidity", unit: "%" },
  { code: "POTM", name: "Potential temperature", unit: "K" },
  { code: "T", name: "Temperature", unit: "K" },
  { code: "WINDVEC", name: "Wind vector", unit: "m/s" },
  { code: "10WINDVEC", name: "10 M wind vector", unit: "m/s" }
];

export const possibleStatus = ["Active", "Blacklisted", "Rejected", "Alarm"];
