import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/actions";

import "./Periods.css";

const possiblePeriods = ["00", "12"];

const Periods = props => {
  const periodsButtons = possiblePeriods.map(p => {
    let classPeriod = "btn-period period-available";
    let disabled = false;
    if (!props.availablePeriods.includes(p)) {
      classPeriod = "btn-period period-missing";
      disabled = true;
    }
    if (p === props.selectedPeriod) {
      classPeriod += " period-selected";
    }
    return (
      <button
        onClick={props.onChangePeriod}
        key={p}
        value={p}
        className={classPeriod}
        disabled={disabled}
      >
        {p}
      </button>
    );
  });

  return (
    <div>
      <div id="sixhour-title">Period</div>
      {periodsButtons}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedPeriod: state.selectedPeriod
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangePeriod: event => {
    return dispatch((_, getState) => {
      dispatch(
        actions.setProperty({
          key: "selectedPeriod",
          value: event.target.value
        })
      );
      dispatch(
        actions.setProperty({
          key: "selectedStationId",
          value: null
        })
      );
      // clear the search box
      dispatch(
        actions.setProperty({
          key: "searchedStation",
          value: ""
        })
      );

      const indexNewPeriod = possiblePeriods.indexOf(event.target.value);
      const newVariables = getState().periods[getState().selectedDate]
        .variables[indexNewPeriod];

      // The variable is not available of the new period
      // set it as the first variable that is available for this new period
      if (!newVariables[getState().selectedVariable]) {
        for (const property in newVariables) {
          if (newVariables[property]) {
            dispatch(
              actions.setProperty({
                key: "selectedVariable",
                value: property
              })
            );
            break;
          }
        }
      }
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Periods);
