import moment from "moment";
import { cloneDeep } from "lodash";

// returns an ordered arrays of all dates between 2 dates
// considering 2 periods (00 and 12) per day
export const rangeBetweenDates = (newestDate, oldestDate) => {
  const s = moment.utc(newestDate);
  const o = moment.utc(oldestDate);

  const periods = ["12", "00"];

  const nbrDays = s.diff(o, "days");

  let arr = [];

  // Add entries for the newest date
  const hourStart = s.hour() === 12 ? "12" : "00";
  const startIndex = periods.indexOf(hourStart);

  for (let j = startIndex; j < periods.length; j++) {
    arr.push(s.utc().format("YYYY-MM-DD") + "T" + periods[j] + ":00:00Z");
  }

  // Add entries for older days
  for (let i = 1; i <= nbrDays - 1; i++) {
    // always substract 1 because substract mutates the original moment by subtracting time.
    const t = s.subtract(1, "days");

    for (let j = 0; j < periods.length; j++) {
      arr.push(t.utc().format("YYYY-MM-DD") + "T" + periods[j] + ":00:00Z");
    }
  }
  // Add entries for the oldest date
  const t = s.subtract(1, "days");

  const hourEnd = o.hour() === 12 ? "12" : "00";
  const endIndex = periods.indexOf(hourEnd);

  for (let j = 0; j <= endIndex; j++) {
    arr.push(t.utc().format("YYYY-MM-DD") + "T" + periods[j] + ":00:00Z");
  }

  return arr.reverse();
};

// Given an array of all dates, returns an object
// containing all the time-series
export const buildTemplateTimeSeries = arrAllDates => {
  const series = cloneDeep(templateTimeSeries);
  const data = arrAllDates.map(d => {
    return { x: d, y: null };
  });

  for (const property in series) {
    series[property].data = cloneDeep(data);
  }
  return series;
};

const templateTimeSeries = {
  avg_an: {
    data: [],
    min: Infinity,
    max: -Infinity,
    color: "#3aefff",
    chart: 2,
    label: "AVG. AN"
  },
  avg_bias: {
    data: [],
    min: Infinity,
    max: -Infinity,
    color: "#ff3a99",
    chart: 2,
    label: "AVG. bias"
  },
  avg_fg: {
    data: [],
    min: Infinity,
    max: -Infinity,
    color: "#ffd13a",
    chart: 2,
    label: "AVG. FG"
  },
  count: {
    data: [],
    // 'count' is the only serie which
    // always has its minimum at 0
    min: 0,
    max: -Infinity,
    color: "#BC8F8F",
    chart: 1,
    label: "Count"
  },
  rms_an: {
    data: [],
    min: Infinity,
    max: -Infinity,
    color: "#565cff",
    chart: 3,
    label: "RMS AN"
  },
  rms_fg: {
    data: [],
    min: Infinity,
    max: -Infinity,
    color: "#4bcf9a",
    chart: 3,
    label: "RMS FG"
  }
};

export const chartsContainer = [
  { id: 1, title: "Count", hasUnit: false },
  { id: 2, title: "Average", hasUnit: true },
  { id: 3, title: "RMS", hasUnit: true }
];
