import { select, event } from "d3-selection";
import { timeFormat, timeParse } from "d3-time-format";

const formatDate = "%Y-%m-%dT%H:%M:%SZ";
const formatString = "%d/%m/%Y %H:%M";
const parserTime = timeParse(formatDate);
const formaterString = timeFormat(formatString);

export class Tooltip {
  constructor() {
    this.init();
  }

  init() {
    this.body = select("body")
      .append("div")
      .attr("class", "tooltip");
  }

  createSimpleItem(serie) {
    const value = serie.data.y;

    const tooltipItem = `<div class="tooltip-item"><span class="tooltip-item-icon" style="background-color: ${serie.color}"></span><div class="tooltip-item-label"><div>${serie.label}:</div><div>${value}</div></div></div>`;

    return tooltipItem;
  }

  show(title, body) {
    // console.log(body);
    let bodyHTML = "";
    if (Object.keys(body).length > 0) {
      bodyHTML = '<div class="tooltip-body">';
      for (const key in body) {
        const serie = body[key];

        bodyHTML += this.createSimpleItem(serie);
      }
      bodyHTML += "</div>";
    }

    this.body
      .html(
        `<div class="tooltip-title">${formaterString(
          parserTime(title)
        )}</div>${bodyHTML}`
      )
      .style("opacity", 0.9)
      .style("position", "absolute")
      .style("top", event.pageY - 20 + "px")
      .style("left", event.pageX - 200 + "px");
  }
}
