import * as actionTypes from "./actions/actionTypes";

const initialState = {
  periods: {},
  dates: [],
  reportTypes: null,
  selectedDate: null,
  selectedPeriod: null,
  selectedVariable: null,
  selectedStatus: "active",
  map: null,
  dataFetched: false,
  reportTypesFetched: false,
  noData: false,
  // selectedStationId is used to filter the layer and zoom on the selected station
  selectedStationId: null,
  // searchedStation is used in the search box
  searchedStation: "",
  APIDataChart: null,
  legendIsVisible: true
};

const setProperty = (state, action) => {
  state[action.payload.key] = action.payload.value;
  return state;
};

const initializeMap = (state, action) => {
  state.map = action.payload;
  return state;
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case actionTypes.SET_PROPERTY:
      return setProperty(newState, action);
    case actionTypes.INITIALIZE_MAP:
      return initializeMap(newState, action);
    default:
      return newState;
  }
};

export default reducer;
