import moment from "moment";

import { URL_API, fetchData } from "../../utils/api";
import * as actionTypes from "./actionTypes";

export const fetchDataFromApi = () => {
  return dispatch => {
    fetchData(`${URL_API}/periods/`).then(response => {
      if (response.data.length === 0) {
        dispatch(
          setProperty({
            key: "noData",
            value: true
          })
        );
        dispatch(
          setProperty({
            key: "dataFetched",
            value: true
          })
        );
      } else {
        let tempObj = {};
        const dates = [];
        for (let p of response.data) {
          const d = moment.utc(p.date).format("YYYY-MM-DD");
          const h = moment.utc(p.date).format("HH");

          if (!tempObj[d]) {
            dates.push(d);
            tempObj[d] = { periods: [], variables: [] };
          }
          tempObj[d].periods.push(h);
          tempObj[d].variables.push(p.variables);
        }

        const mostRecentDate = dates[dates.length - 1];
        const defaultPeriod =
          tempObj[mostRecentDate].periods[
            tempObj[mostRecentDate].periods.length - 1
          ];

        // Set the default variable as the first one that
        // has a vlue 'true' for the latest period of the
        // latest date
        let defaultVariable;
        for (const property in tempObj[mostRecentDate].variables[0]) {
          if (
            tempObj[mostRecentDate].variables[
              tempObj[mostRecentDate].variables.length - 1
            ][property]
          ) {
            defaultVariable = property;
            break;
          }
        }

        dispatch(
          setProperty({
            key: "dates",
            value: dates
          })
        );
        // the default date is the most recent one (the last one)
        dispatch(
          setProperty({
            key: "selectedDate",
            value: mostRecentDate
          })
        );
        dispatch(
          setProperty({
            key: "selectedPeriod",
            value: defaultPeriod
          })
        );
        dispatch(
          setProperty({
            key: "selectedVariable",
            value: defaultVariable
          })
        );
        dispatch(
          setProperty({
            key: "periods",
            value: tempObj
          })
        );
        dispatch(
          setProperty({
            key: "dataFetched",
            value: true
          })
        );
      }
    });
  };
};

export const fetchReportTypesFromApi = () => {
  return dispatch => {
    fetchData(`${URL_API}/report-types/`).then(response => {
      const reportTypes = {};
      response.data.forEach(item => {
        reportTypes[item.code] = { name: item.name, color: item.color };
      });
      dispatch(
        setProperty({
          key: "reportTypes",
          value: reportTypes
        })
      );
      dispatch(
        setProperty({
          key: "reportTypesFetched",
          value: true
        })
      );
    });
  };
};

export const initializeMap = data => {
  return {
    type: actionTypes.INITIALIZE_MAP,
    payload: data
  };
};

export const setProperty = data => {
  return { type: actionTypes.SET_PROPERTY, payload: data };
};
