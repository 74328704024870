import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import mapboxgl from "mapbox-gl";
import * as actions from "../../store/actions/actions";

import { URL_VECTOR_TILES } from "../../utils/api";
import {
  MAPBOX_KEY,
  MAPBOX_BACKGROUND,
  getReportTypes,
  buildLegendObject,
  filterNoFeature,
  colors
} from "../../utils/map";

import SearchBox from "../SearchBox/SearchBox";
import MapSpinner from "../MapSpinner/MapSpinner";
import Legend from "../Legend/Legend";
import Popup from "../Popup/Popup";
import Modal from "../Modal/Modal";

import zoomControlIcon from "../../images/icons/globe.svg";
import legendControlIcon from "../../images/icons/align-justify.svg";

import "./Map.css";

mapboxgl.accessToken = MAPBOX_KEY;

const popup = new mapboxgl.Popup();

let mapStyle = ["case"];

const Map = props => {
  const [done, setDone] = useState(false);
  const [firstLayerAdded, setFirstLayerAdded] = useState(false);
  const [layerIsLoaded, setLayerIsLoaded] = useState(true);
  const [mapOpacity, setMapOpacity] = useState(1);
  const [legendObject, setLegendObject] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  // metadataChart is passed to the Modal component
  const [metadataChart, setMetadataChart] = useState(null);

  // 'filter' is used to keep the filter when we change layer
  const addLayer = (properties, filter) => {
    popup.remove();

    const layerName = `${properties.selectedVariable}-${properties.selectedStatus}-${properties.selectedDate}-${properties.selectedPeriod}`;
    properties.map.addLayer({
      id: layerName,
      type: "circle",
      source: {
        type: "vector",
        tiles: [
          `${URL_VECTOR_TILES}/{z}/{x}/{y}/?variable=${properties.selectedVariable}&status=${properties.selectedStatus}&date=${properties.selectedDate}&period=${properties.selectedPeriod}`
        ],
        minzoom: 0,
        maxzoom: 16
      },
      "source-layer": layerName,
      paint: {
        "circle-radius": {
          base: 1,
          stops: [
            [4, 3],
            [12, 6]
          ]
        },
        "circle-color": mapStyle
      },
      filter
    });
    properties.map.addLayer({
      id: `${layerName}-filter`,
      type: "circle",
      source: {
        type: "vector",
        tiles: [
          `${URL_VECTOR_TILES}/{z}/{x}/{y}/?variable=${properties.selectedVariable}&status=${properties.selectedStatus}&date=${properties.selectedDate}&period=${properties.selectedPeriod}`
        ],
        minzoom: 0,
        maxzoom: 16
      },
      "source-layer": layerName,
      paint: {
        "circle-radius": {
          base: 1,
          stops: [
            [4, 3],
            [12, 6]
          ]
        },
        "circle-color": colors.map["selection-circle"],
        "circle-stroke-color": colors.map["selection-circle-stroke"],
        "circle-stroke-width": 1
      },
      filter: ["==", ["get", "station_id"], null]
    });

    addEventsOnLayer(properties.map, layerName);

    getReportTypes(
      properties.selectedVariable,
      properties.selectedDate,
      properties.selectedStatus,
      properties.selectedPeriod
    ).then(response => {
      setLegendObject(buildLegendObject(properties.reportTypes, response.data));
    });
  };

  const onClick = e => {
    const firstElement = e.features[0];
    const coordinates = firstElement.geometry.coordinates.slice();
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const metadata = {
      station_id: firstElement.properties.station_id,
      rt_id: firstElement.properties.reportype,
      rt_name: props.reportTypes[firstElement.properties.reportype].name,
      latitude: Math.round(coordinates[1] * 1000) / 1000,
      longitude: Math.round(coordinates[0] * 1000) / 1000,
      selectedVariable: props.selectedVariable,
      selectedDate: props.selectedDate,
      selectedStatus: props.selectedStatus,
      selectedPeriod: props.selectedPeriod
    };
    popup
      .setLngLat(coordinates)
      .setHTML("")

      .addTo(props.map);

    setMetadataChart(metadata);

    ReactDOM.render(
      // Provider is only needed if connect Popup to redux
      // <Provider store={store} fileType={props.fileType}>
      <Popup
        obj={popup}
        metadata={metadata}
        setModalVisible={setModalVisible}
        onSetAPIDataChart={props.onSetAPIDataChart}
      />,
      document.querySelector(".mapboxgl-popup-content")
    );
  };

  const addEventsOnLayer = (map, layerId) => {
    // When a click event occurs on a feature open a popup at the
    // location of the feature, with description HTML from its properties.
    map.on("click", layerId, onClick);

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on("mouseenter", layerId, function() {
      map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    map.on("mouseleave", layerId, function() {
      map.getCanvas().style.cursor = "";
    });
  };

  // Initialize the map
  useEffect(() => {
    if (!mapboxgl.supported()) {
      // eslint-disable-next-line no-alert
      alert("Your browser does not support Mapbox GL");
    }
    const mapObject = new mapboxgl.Map({
      container: "map",
      style: MAPBOX_BACKGROUND,
      zoom: 1.2,
      center: [0, 22]
    });

    props.onInitializeMap(mapObject);
    setDone(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Display the first layer
  useEffect(() => {
    if (
      done &&
      props.dataFetched &&
      !props.noData &&
      props.reportTypesFetched &&
      props.map !== null
    ) {
      setMapOpacity(0.2);
      setLayerIsLoaded(false);

      // Building the style with all existing report types
      for (const rp in props.reportTypes) {
        const class_rp = ["==", ["get", "reportype"], rp];
        mapStyle.push(class_rp);
        mapStyle.push(props.reportTypes[rp].color);
      }
      // if `mapStyle` is equals to `["case"]` which means
      // that `props.reportTypes` is empty, add a style
      // for a 'fake' report type that we know doesn't exist (-9999)
      // and will not be displayed.
      // This is necessary to produce a valid MapBox style
      // In this case all report types will be displayed as
      // the color `colors.rt.missing`
      if (mapStyle.length === 1) {
        mapStyle.push(["==", ["get", "reportype"], -9999]);
        mapStyle.push(colors.rt.empty);
      }
      // All report types present in the layer but not in
      // 'props.reportTypes' will be displayed as `colors.rt.missing`
      mapStyle.push(colors.rt.missing);

      props.map.on("load", event => {
        addLayer(props, filterNoFeature);
      });
      setFirstLayerAdded(true);
      // When the layer is loaded, set the opacity to 1
      // and remove the spinner
      props.map.once("idle", function() {
        setMapOpacity(1);
        setLayerIsLoaded(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done, props.dataFetched, props.reportTypesFetched]);

  // Change the layer through the map menu
  useEffect(() => {
    if (firstLayerAdded) {
      let filter;
      if (props.map.getStyle().layers.length > 1) {
        const obsLayerIdFilter = props.map.getStyle().layers[
          props.map.getStyle().layers.length - 1
        ].id;
        const obsLayerId = props.map.getStyle().layers[
          props.map.getStyle().layers.length - 2
        ].id;

        // Set the map with transparency and display the spinner
        setMapOpacity(0.2);
        setLayerIsLoaded(false);

        // get the filter of the layer we are about to remove
        filter = props.map.getFilter(obsLayerId);

        props.map.removeLayer(obsLayerId);
        props.map.removeLayer(obsLayerIdFilter);
        props.map.removeSource(obsLayerId);
        props.map.removeSource(obsLayerIdFilter);
      }
      // pass the filter to the new layer
      addLayer(props, filter);
      // When the layer is loaded, set the opacity to 1
      // and remove the spinner
      props.map.once("idle", function() {
        setMapOpacity(1);
        setLayerIsLoaded(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.selectedPeriod,
    props.selectedDate,
    props.selectedVariable,
    props.selectedStatus
  ]);

  // when a station is selected in the search box
  useEffect(() => {
    if (props.selectedStationId) {
      const obsLayerIdFilter = props.map.getStyle().layers[
        props.map.getStyle().layers.length - 1
      ].id;

      props.map.setFilter(obsLayerIdFilter, [
        "==",
        ["get", "station_id"],
        props.selectedStationId.station
      ]);

      const arrCoordinates = props.selectedStationId.coords.map(f => [
        f.longitude,
        f.latitude
      ]);

      const bounds = arrCoordinates.reduce(function(bounds, coord) {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(arrCoordinates[0], arrCoordinates[0]));

      popup.remove();
      props.map.fitBounds(bounds, {
        padding: 20,
        maxZoom: 14
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedStationId]);

  let spinner;
  if (!layerIsLoaded) {
    spinner = <MapSpinner />;
  }

  let legend;
  if (legendObject) {
    const obsLayerId = props.map.getStyle().layers[
      props.map.getStyle().layers.length - 2
    ].id;
    const currentFilter = props.map.getFilter(obsLayerId);
    legend = (
      <Legend
        isVisible={props.legendIsVisible}
        listReportTypes={legendObject}
        currentFilter={currentFilter}
      />
    );
  }

  return (
    <>
      <div id="map" style={{ opacity: mapOpacity }}>
        <SearchBox />
        {legend}
      </div>
      {spinner}
      <Modal
        visible={modalVisible}
        setVisible={setModalVisible}
        metadata={metadataChart}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    map: state.map,
    selectedDate: state.selectedDate,
    selectedVariable: state.selectedVariable,
    selectedPeriod: state.selectedPeriod,
    selectedStatus: state.selectedStatus,
    selectedStationId: state.selectedStationId,
    dataFetched: state.dataFetched,
    noData: state.noData,
    reportTypesFetched: state.reportTypesFetched,
    reportTypes: state.reportTypes,
    legendIsVisible: state.legendIsVisible
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onInitializeMap: data => {
      return dispatch((_, getState) => {
        dispatch(actions.initializeMap(data));
        let currentState = getState();
        currentState.map.addControl(
          new mapboxgl.NavigationControl({
            showCompass: false
          })
        );
        // disable map rotation using right click + drag
        currentState.map.dragRotate.disable();

        // disable map rotation using touch rotation gesture
        currentState.map.touchZoomRotate.disableRotation();

        // Add map control to go back to initial zoom level
        const controlTopRight = document.getElementsByClassName(
          "mapboxgl-ctrl-top-right"
        )[0];
        const divMapControlZoom = document.createElement("div");
        divMapControlZoom.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
        divMapControlZoom.innerHTML = `<button class="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom" type="button" aria-label="Go back to initial zoom level" title="Go back to initial zoom level"><img class="control-icon" src="${zoomControlIcon}"></button>`;
        controlTopRight.appendChild(divMapControlZoom);

        const zoomButton = document.getElementsByClassName(
          "mapboxgl-ctrl-zoom"
        )[0];

        zoomButton.addEventListener("click", function() {
          unZoomMap(currentState.map);
        });

        const divMapControlLegend = document.createElement("div");
        divMapControlLegend.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
        divMapControlLegend.innerHTML = `<button class="mapboxgl-ctrl-icon mapboxgl-ctrl-legend" type="button" aria-label="Toggle legend" title="Toggle legend"><img class="control-icon" src="${legendControlIcon}"></button>`;
        controlTopRight.appendChild(divMapControlLegend);

        const legendButton = document.getElementsByClassName(
          "mapboxgl-ctrl-legend"
        )[0];

        legendButton.addEventListener("click", () => {
          currentState = getState();
          dispatch(
            actions.setProperty({
              key: "legendIsVisible",
              value: !currentState.legendIsVisible
            })
          );
        });
      });
    },
    onSetAPIDataChart: arr => {
      return dispatch(() => {
        dispatch(
          actions.setProperty({
            key: "APIDataChart",
            value: arr
          })
        );
      });
    }
  };
};

const unZoomMap = mapObject => {
  // back to initial zoom
  mapObject.jumpTo({
    zoom: 1.2,
    center: [0, 22]
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
