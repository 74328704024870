import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  rangeBetweenDates,
  buildTemplateTimeSeries,
  chartsContainer
} from "../../utils/timeseries";
import { possibleVariables } from "../../utils/parameters";

import Chart from "../Chart/Chart";
import { Modal, Spin } from "antd";

import "./Modal.css";

const ModalChart = props => {
  const [metadata, setMetadata] = useState(<Spin tip="Loading..." />);
  const [tabs, setTabs] = useState(<Spin tip="Loading..." />);
  const [body, setBody] = useState(<Spin tip="Loading..." />);
  const [dataChart, setDataChart] = useState(null);
  const [rangeX, setRangeX] = useState(null);
  const [clickedOnce, setClickedOnce] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [noData, setNoData] = useState(false);

  const triggerChartHander = evt => {
    setClickedOnce(true);

    const clickedValue = evt.target.value;
    setSelectedLayer(clickedValue);

    let tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      if (tablinks[i].value === clickedValue) {
        tablinks[i].className += " active";
      } else {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
    }
    // Remove the tooltip
    try {
      const tooltipElements = document.getElementsByClassName("tooltip");
      while (tooltipElements[0]) {
        tooltipElements[0].parentNode.removeChild(tooltipElements[0]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (props.APIDataChart) {
      if (props.APIDataChart.length === 0) {
        setNoData(true);
      }
      const newestDate = `${props.selectedDate} ${props.selectedPeriod}:00:00`;
      const oldestDate = `${props.dates[0]} 00:00:00`;

      const allDates = rangeBetweenDates(newestDate, oldestDate);

      let layers = {};
      let layersForTabs = [];
      for (let index = 0; index < props.APIDataChart.length; index++) {
        const element = props.APIDataChart[index];
        // get the index of the date
        const dateIndex = allDates.indexOf(element.date);
        // update the value of the property 'y'

        // if the layer doesn't exist, fill it with the template object
        if (!layers.hasOwnProperty(element.layer_top)) {
          layers[element.layer_top] = buildTemplateTimeSeries(allDates);
          layersForTabs.push({
            top: element.layer_top,
            bottom: element.layer_bottom
          });
        }
        // replace the null values with the correct one for all series

        for (const property in layers[element.layer_top]) {
          // update the 'y' property
          layers[element.layer_top][property].data[dateIndex].y =
            element[property];

          // update the min and max
          if (
            layers[element.layer_top][property].data[dateIndex].y !== null &&
            layers[element.layer_top][property].data[dateIndex].y <
              layers[element.layer_top][property].min
          ) {
            layers[element.layer_top][property].min =
              layers[element.layer_top][property].data[dateIndex].y;
          }
          if (
            layers[element.layer_top][property].data[dateIndex].y !== null &&
            layers[element.layer_top][property].data[dateIndex].y >
              layers[element.layer_top][property].max
          ) {
            layers[element.layer_top][property].max =
              layers[element.layer_top][property].data[dateIndex].y;
          }
        }
      }
      setDataChart(layers);
      setRangeX(allDates);

      // sort layers
      layersForTabs.sort((a, b) =>
        a.top > b.top ? 1 : b.top > a.top ? -1 : 0
      );

      const tabsElements = layersForTabs.map((l, i) => {
        let classTab = "tablinks";
        if (!clickedOnce && i === 0) {
          classTab = "tablinks active";
          setSelectedLayer(l.top);
        }
        return (
          <button
            key={i}
            className={classTab}
            onClick={triggerChartHander}
            value={l.top}
          >
            {l.top} - {l.bottom}
          </button>
        );
      });

      setMetadata(
        <div className="metadata">
          <div>Station ID: {props.metadata.station_id}</div>
          <div>
            Report type: {props.metadata.rt_name} ({props.metadata.rt_id})
          </div>
          <div>
            Variable:{" "}
            {
              possibleVariables.filter(
                v => v.code === props.metadata.selectedVariable
              )[0].name
            }
          </div>
        </div>
      );

      setTabs(<div className="tab">{tabsElements}</div>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.APIDataChart]);

  useEffect(() => {
    if (selectedLayer !== null) {
      const container = chartsContainer.map(c => {
        let series = {};
        for (const serie in dataChart[selectedLayer]) {
          if (dataChart[selectedLayer][serie].chart === c.id) {
            series[serie] = dataChart[selectedLayer][serie];
          }
        }
        let unit;
        if (c.hasUnit) {
          unit = possibleVariables.filter(
            v => v.code === props.metadata.selectedVariable
          )[0].unit;
        }
        return (
          <Chart
            key={c.id}
            id={c.id}
            title={c.title}
            data={series}
            rangeX={rangeX}
            layer={selectedLayer}
            unit={unit}
          />
        );
      });

      setBody(<div className="chart-level2">{container}</div>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLayer]);

  // If there is no data for the time-series
  useEffect(() => {
    if (noData) {
      setTabs(null);
      setBody(
        <div className="chart-level2">
          <div className="container-nodata">There is no data</div>
        </div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noData]);

  // When closing the modal we reset everything
  const closeModal = () => {
    props.setVisible(false);

    setClickedOnce(false);
    setTabs(null);
    setBody(null);
    setDataChart(null);
    setSelectedLayer(null);
    setNoData(false);

    // Remove the tooltip
    try {
      const tooltipElements = document.getElementsByClassName("tooltip");
      while (tooltipElements[0]) {
        tooltipElements[0].parentNode.removeChild(tooltipElements[0]);
      }
    } catch (error) {}
  };

  return (
    <Modal
      title=" "
      visible={props.visible}
      centered={true}
      onCancel={closeModal}
      footer={null}
    >
      <div className="chart-level1">
        {metadata}
        {tabs}
        {body}
      </div>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    dates: state.dates,
    selectedDate: state.selectedDate,
    selectedPeriod: state.selectedPeriod,
    APIDataChart: state.APIDataChart
  };
};

export default connect(mapStateToProps)(ModalChart);
