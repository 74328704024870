import React, { useEffect } from "react";
import D3Chart from "../../utils/chart";

import "./Chart.css";

const Chart = props => {
  const legend = Object.keys(props.data).map(s => {
    return (
      <div key={s} className="legend-element">
        <div
          className="legend-element-color"
          style={{
            backgroundColor: props.data[s].color
          }}
        ></div>
        <div className="legend-element-label">{props.data[s].label}</div>
      </div>
    );
  });

  useEffect(() => {
    // Remove the SVG element from the DOM
    try {
      const SVGElement = document.getElementById(`svg-time-series-${props.id}`);
      SVGElement.parentNode.removeChild(SVGElement);
    } catch (error) {}

    new D3Chart(
      `chart-${props.id}`,
      props.id,
      props.data,
      props.rangeX,
      props.unit
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.layer]);

  return (
    <div className="chart-container">
      <div className="chart-space"></div>
      <div className="chart-title">{props.title}</div>
      <div className="chart-legend">{legend}</div>
      <div id={`chart-${props.id}`} className="chart-element"></div>
    </div>
  );
};

export default Chart;
