import axios from "axios";

export const fetchData = url => {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// 'promiseAuth' is used to 'simulate' locally
// the check to see if the user is logged in or not.
// To simulate a not logged in user, set the property
// 'user' to null.
// To simulate a logged in user, set the property
// 'user' to a non null value, e.g 'mapv'.
export let promiseAuth;
if (process.env.REACT_APP_BUILD_ENV === "local") {
  promiseAuth = Promise.resolve({
    user: "mapv"
  });
} else {
  promiseAuth = fetch(`${process.env.REACT_APP_AUTH_BACKEND}/frontend/config`, {
    credentials: "include"
  }).then(response => response.json());
}

export const URL_API = process.env.REACT_APP_API_BASE_URL;
export const URL_VECTOR_TILES = process.env.REACT_APP_VT_BASE_URL;

// OSCAR
export const URL_OSCAR_SURFACE_SEARCH =
  "https://oscar.wmo.int/surface/index.html#/search/station/stationReportDetails";
