import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/actions";

import { URL_API, fetchData } from "../../utils/api";

import SearchResults from "../SearchResults/SearchResults";

import "./SearchBox.css";

const SearchBox = props => {
  const [arrStations, setArrStations] = useState([]);

  // Event fired when the input value is changed
  const onChange = e => {
    props.onSetSearchedStation(e.currentTarget.value);

    if (e.currentTarget.value.length >= 2) {
      fetchData(
        `${URL_API}/stations/?variable=${props.selectedVariable}&date=${props.selectedDate}&status=${props.selectedStatus}&period=${props.selectedPeriod}&id=${e.currentTarget.value}`
      ).then(response => {
        setArrStations(response.data.map(s => s.station_id));
      });
    } else {
      setArrStations([]);
    }
  };

  // Event fired when the user clicks on a suggestion
  const handleClick = e => {
    const station_id = e.currentTarget.innerText;

    fetchData(
      `${URL_API}/station-coordinates/?station_id=${station_id}&variable=${props.selectedVariable}&date=${props.selectedDate}&status=${props.selectedStatus}&period=${props.selectedPeriod}`
    ).then(response => {
      // console.log(response.data);
      props.onSetSelectedStationId({
        station: station_id,
        coords: response.data
      });
    });

    props.onSetSearchedStation(station_id);

    setArrStations([]);
  };

  return (
    <div className="filter-ctrl">
      <input
        id="filter-input"
        type="text"
        name="filter"
        placeholder="Search station"
        onChange={onChange}
        value={props.searchedStation}
        autoComplete="off"
      />
      <SearchResults elements={arrStations} onClick={handleClick} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedPeriod: state.selectedPeriod,
    selectedDate: state.selectedDate,
    selectedVariable: state.selectedVariable,
    selectedStatus: state.selectedStatus,
    searchedStation: state.searchedStation
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSetSelectedStationId: data => {
    return dispatch(() => {
      dispatch(
        actions.setProperty({
          key: "selectedStationId",
          value: data
        })
      );
    });
  },
  onSetSearchedStation: data => {
    return dispatch(() => {
      dispatch(
        actions.setProperty({
          key: "searchedStation",
          value: data
        })
      );
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
