import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../src/store/actions/actions";
import { promiseAuth } from "./utils/api";

import NotLoggedIn from "./components/NotLoggedIn/NotLoggedIn";
import Map from "./components/Map/Map";
import Menu from "./components/Menu/Menu";

import "./App.css";

const App = props => {
  const [mainComponent, setMainComponent] = useState(null);

  useEffect(() => {
    promiseAuth
      .then(data => {
        // If the user is logged in...
        if (data.user !== null) {
          // display the app...
          setMainComponent(
            <>
              <Menu />
              <Map />
            </>
          );
          props.onFetchDataFromApi();
          props.onFetchReportTypesFromApi();
        } else {
          // If the user is not logged in, display a message
          // asking him to log in
          setMainComponent(<NotLoggedIn />);
        }
      })
      .catch(function(err) {
        console.log("Fetch Error : ", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{mainComponent}</>;
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchDataFromApi: payload => {
      dispatch(actions.fetchDataFromApi());
    },
    onFetchReportTypesFromApi: payload => {
      dispatch(actions.fetchReportTypesFromApi());
    }
  };
};

export default connect(null, mapDispatchToProps)(App);
