import React from "react";

import "./NotLoggedIn.css";

const NotLoggedIn = props => {
  return (
    <div className="not-logged-in">
      &#9888; Log in to access the Observation Monitoring application
    </div>
  );
};

export default NotLoggedIn;
