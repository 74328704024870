import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  buildClassFilter,
  filterAllFeatures,
  filterNoFeature,
  indexOfArray
} from "../../utils/map";

import "./Legend.css";

const Legend = props => {
  const [body, setBody] = useState(<div>Loading...</div>);
  const [partSelection, setPartSelection] = useState(null);

  const filterLayer = event => {
    const classes = event.target.className.split(" ");
    const rp = event.target.getAttribute("data-rp");
    const globalFilter = document
      .getElementsByClassName("btn-globalfilter")[0]
      .getAttribute("data-globalfilter");

    // -2 because the last layer is the one used for
    // searching station, with the `-filter' suffix
    const layerName = props.map.getStyle().layers[
      props.map.getStyle().layers.length - 2
    ].id;

    const currentFilter = props.map.getFilter(layerName);

    event.target.classList.add("unselected");

    let sign = "!=";
    // get the filter for this class
    let newFilter = buildClassFilter(sign, rp);

    if (globalFilter === "off") {
      // Adding the class back
      if (classes.includes("unselected")) {
        event.target.classList.remove("unselected");
        const index = indexOfArray(newFilter, currentFilter);
        currentFilter.splice(index, 1);
      } else {
        // filtering out the class

        currentFilter.push(newFilter);
      }
    } else {
      // if the global filter button is active
      sign = "==";
      // get the filter for this class
      newFilter = buildClassFilter(sign, rp);
      // Adding the class back
      if (classes.includes("unselected")) {
        event.target.classList.remove("unselected");
        // TODO: Check if can have duplicates in 'currentFilter'
        // If yes, remove them
        currentFilter.push(newFilter);
      } else {
        // filtering out the class
        const index = indexOfArray(newFilter, currentFilter);
        currentFilter.splice(index, 1);
      }
    }
    props.map.setFilter(layerName, currentFilter);
  };

  // To hide or show all classes at once
  const filterAll = el => {
    const currentValue = el.target.getAttribute("data-globalfilter");
    el.target.setAttribute(
      "data-globalfilter",
      currentValue === "off" ? "on" : "off"
    );

    // -2 because the last layer is the one used for
    // searching station, with the `-filter' suffix
    const layerName = props.map.getStyle().layers[
      props.map.getStyle().layers.length - 2
    ].id;

    const classElements = document.getElementsByClassName(
      "legend-class-clickable"
    );

    if (el.currentTarget.innerHTML === "Hide all") {
      props.map.setFilter(layerName, filterAllFeatures);
      el.currentTarget.innerHTML = "Show all";

      Array.from(classElements).forEach(el => {
        el.classList.add("unselected");
      });
    } else {
      props.map.setFilter(layerName, filterNoFeature);
      el.currentTarget.innerHTML = "Hide all";
      Array.from(classElements).forEach(c => {
        c.classList.remove("unselected");
      });
    }
  };

  useEffect(() => {
    if (props.listReportTypes) {
      // get the report-types code that are in the current filter
      const rtFiltered = props.currentFilter
        .filter(f => {
          // skip the first element which is a string and
          // the filters used to filter all or no features
          return typeof f === "object" && f[2] !== -1;
        })
        .map(f => f[2]);

      const typeFilter = props.currentFilter[0];

      let newBody;
      if (props.listReportTypes.length > 0) {
        newBody = props.listReportTypes.map(c => {
          let classStatusReportType;
          if (typeFilter === "any") {
            if (rtFiltered.includes(c.id)) {
              classStatusReportType = "legend-class-clickable";
            } else {
              classStatusReportType = "legend-class-clickable unselected";
            }
          }
          if (typeFilter === "all") {
            if (rtFiltered.includes(c.id)) {
              classStatusReportType = "legend-class-clickable unselected";
            } else {
              classStatusReportType = "legend-class-clickable";
            }
          }

          return (
            <div key={c.id} className="legend-class-row">
              <div
                className={classStatusReportType}
                key={c.id}
                onClick={filterLayer}
                data-rp={c.id}
              >
                <span
                  className="legend-element-style"
                  style={{
                    backgroundColor: c.color
                  }}
                />
                {c.name}
              </div>
            </div>
          );
        });
      } else {
        newBody = <div>&#9888;This layer is empty</div>;
      }
      setBody(newBody);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listReportTypes, props.listReportTypes]);

  useEffect(() => {
    // if the user chooses a station in the search box
    // display it in the legend
    if (props.selectedStationId) {
      setPartSelection(
        <div className="legend-part-selection">
          <span className="legend-element-style legend-element-selection" />
          Selected station: {props.selectedStationId.station}
        </div>
      );
    } else {
      // when the user changes the map, 'selectedStationId' is set to null
      // so we remove the selection part in the legend
      setPartSelection(null);
    }
  }, [props.selectedStationId]);

  const visible = props.isVisible ? "block" : "none";

  const divStyle = {
    display: visible
  };

  return (
    <div className="legend" style={divStyle}>
      {partSelection}
      <div className="legend-header">
        <h4>Report types</h4>
        <button
          onClick={filterAll}
          className="btn-globalfilter"
          data-globalfilter="off"
        >
          Hide all
        </button>
      </div>
      {body}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    map: state.map,
    visibleReportTypes: state.visibleReportTypes,
    selectedStationId: state.selectedStationId
  };
};

export default connect(mapStateToProps)(Legend);
