import { URL_API, fetchData } from "./api";

// MapBox
// ECMWF access token
export let MAPBOX_KEY;

if (window.location.hostname.includes("ecmwf.int")) {
  // ECMWF restricted key
  MAPBOX_KEY =
    "pk.eyJ1IjoicHZlcm5pZXItZWNtd2YiLCJhIjoiY2s4cmJ0dWR4MDFqZDNtcGVldHVsbDZjOCJ9.wvpy9SJv1qyyS5cnHLhAuw";
} else {
  // For development we use the default public key
  MAPBOX_KEY =
    "pk.eyJ1IjoicHZlcm5pZXItZWNtd2YiLCJhIjoiY2s4cmJuODB5MDFpZTNucjJ1bjJkbHA4ZSJ9.4eKbjxX5P0UU43WN0UJphA";
}

// Background layer
export const MAPBOX_BACKGROUND =
  "mapbox://styles/mapbox/outdoors-v10?optimize=true";

export const getReportTypes = async (variable, date, status, period) => {
  return fetchData(
    `${URL_API}/report-types-by-map/?variable=${variable}&date=${date}&status=${status}&period=${period}`
  );
};

export const getTimeSeries = (
  variable,
  date,
  status,
  period,
  station_id,
  report_type
) => {
  return fetchData(
    `${URL_API}/time-series/?station_id=${station_id}&date=${date}&period=${period}&variable=${variable}&report_type=${report_type}&status=${status}`
  );
};

export const colors = {
  map: {
    "selection-circle": "#7aff33",
    "selection-circle-stroke": "#0f0401"
  },
  rt: {
    // 'empty' could be any color, this will never be displayed
    // as it's associated to a report type that doesn't exist
    empty: "white",
    missing: "black"
  }
};

// `reportTypes` is the list of all existing
// report types, returned by the API
// `rtMap` is the array of report types
// present on the current map, returned
// by the API endpoint above
export const buildLegendObject = (reportTypes, rtMap) => {
  return rtMap.map(r => {
    let obj;
    if (reportTypes[r.reportype]) {
      obj = {
        id: r.reportype,
        ...reportTypes[r.reportype]
      };
    } else {
      // if the report type is not in `reportTypes`
      obj = {
        id: r.reportype,
        name: r.reportype,
        color: colors.rt.missing
      };
    }
    return obj;
  });
};

export const buildClassFilter = (sign, rp) => {
  const arr = [sign, ["get", "reportype"], rp];
  return arr;
};

// we use a value for report type that doesn't exist
// so that we are sure to hide all classes
export const filterAllFeatures = ["any", ["==", ["get", "reportype"], -1]];

// initial filter that doesn't filter anything
export const filterNoFeature = ["all", ["!=", ["get", "reportype"], -1]];

// returns the index of an array inside another array, -1 otherwise
// source: https://stackoverflow.com/questions/6315180/javascript-search-array-of-arrays
export const indexOfArray = (val, array) => {
  var hash = {};
  for (let i = 0; i < array.length; i++) {
    hash[array[i]] = i;
  }
  return hash.hasOwnProperty(val) ? hash[val] : -1;
};
