import React from "react";

import { getTimeSeries } from "../../utils/map";
import { URL_OSCAR_SURFACE_SEARCH } from "../../utils/api";

import "./Popup.css";

const arrRTCheckOSCAR = ["16001", "16002", "16022", "16045", "16076", "98001"];

const Popup = props => {
  const onGetTimeSeries = () => {
    props.setModalVisible(true);
    getTimeSeries(
      props.metadata.selectedVariable,
      props.metadata.selectedDate,
      props.metadata.selectedStatus,
      props.metadata.selectedPeriod,
      props.metadata.station_id,
      props.metadata.rt_id
    ).then(response => {
      props.onSetAPIDataChart(response.data);
    });
  };

  let linkOSCAR;
  // if the report type is within arrRTCheckOSCAR and
  // the station ID only contains numbers and
  // is not more than 5 digits long
  if (
    arrRTCheckOSCAR.includes(props.metadata.rt_id) &&
    /^\d+$/.test(props.metadata.station_id) &&
    props.metadata.station_id.length <= 5
  ) {
    // if the station ID is less than 5 digits long
    // add '0's in front so that 'id' is 5 digits long
    const id = `${"0".repeat(5 - props.metadata.station_id.length)}${
      props.metadata.station_id
    }`;
    const url = `${URL_OSCAR_SURFACE_SEARCH}/0-20000-0-${id}`;
    linkOSCAR = (
      <a target="_blank" rel="noopener noreferrer" href={url}>
        Open in OSCAR
      </a>
    );
  }
  const body = (
    <div className="popup-props-container">
      <div>Station ID: {props.metadata.station_id}</div>
      <div>
        Report type: {props.metadata.rt_name} ({props.metadata.rt_id})
      </div>

      <div>Latitude: {props.metadata.latitude}</div>
      <div>Longitude: {props.metadata.longitude}</div>
      {linkOSCAR}
      <button className="bt-link-ts" onClick={onGetTimeSeries}>
        Time-series
      </button>
    </div>
  );

  return (
    <>
      <button
        onClick={props.obj.remove}
        className="mapboxgl-popup-close-button"
        type="button"
        aria-label="Close popup"
      >
        ×
      </button>
      <div id="popup-content">{body}</div>
    </>
  );
};

export default Popup;
