import { select, selectAll, mouse } from "d3-selection";
import { min, max, extent, bisector } from "d3-array";
import { line } from "d3-shape";
import { scaleLinear, scaleTime } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { format } from "d3-format";
import { timeParse, timeFormat } from "d3-time-format";
import { transition } from "d3-transition";
import { Tooltip } from "./tooltip";

export default class D3Chart {
  constructor(parentElement, id, data, rangeX, unit) {
    this.parentElement = parentElement;
    this.id = id;
    this.data = data;
    this.rangeX = rangeX;
    this.unit = unit;
    this.initVis();
  }

  initVis() {
    this.margin = { top: 20, right: 20, bottom: 20, left: 70 };

    const containerChart = document.getElementById(this.parentElement);
    const positionInfo = containerChart.getBoundingClientRect();

    this.width = positionInfo.width - this.margin.left - this.margin.right;
    this.height = positionInfo.height - this.margin.top - this.margin.bottom;

    // 1) Set up the SVG and g elements
    this.svg = select(`#${this.parentElement}`)
      .append("svg")
      .attr("id", `svg-time-series-${this.id}`)
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);

    this.g = this.svg
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    this.t = () => {
      return transition().duration(1000);
    };

    // 2) Calculate the axis
    // TODO: this doesn't work for monthly data
    const formatDate = "%Y-%m-%dT%H:%M:%SZ";
    this.parserTime = timeParse(formatDate);
    this.x = scaleTime()
      .range([0, this.width])
      .nice()
      .domain(extent(this.rangeX, d => this.parserTime(d)));

    // calculate the data min and max to know the domain for the Y axis
    const maxs = [];
    const mins = [];
    for (const key in this.data) {
      maxs.push(this.data[key].max);
      mins.push(this.data[key].min);
    }
    this.maxY = max(maxs);
    // if this.metadataYAxis.min is defined use it, else calculate the min of all series
    this.minY = min(mins);

    this.y = scaleLinear()
      .domain([this.minY, this.maxY])
      .nice()
      .range([this.height, 0]);

    const xTicks = this.rangeX.length > 5 ? 5 : this.rangeX.length;
    const yTicks = 5;

    this.xAxisCall = axisBottom(this.x)
      .ticks(xTicks)
      .tickFormat(timeFormat("%Y-%m-%d"));

    this.yAxisCall = axisLeft(this.y)
      .ticks(yTicks)
      .tickFormat(format(getAxisPrecision(this.maxY - this.minY)));

    this.xAxis = this.g
      .append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(this.xAxisCall);

    this.yAxis = this.g
      .append("g")
      .attr("transform", "translate(0, 0)")
      .call(this.yAxisCall);

    // Y-Axis label
    this.g
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -58)
      .attr("x", -(this.height / 2))
      .attr("dy", ".6em")
      .style("text-anchor", "middle")
      .attr("fill", "#5D6971")
      .text(this.unit);

    this.wrangleData();
  }

  wrangleData() {
    for (const serie in this.data) {
      // we check if there are some isolated values that have to be representend as points
      this.data[serie].isolated = identifyIsolatedValues(this.data[serie]);

      // For all series add a new property `rangeX` that contains
      // all the steps of this serie
      // this will be used when hovering over the data to check if
      // the highlighted step is in r=the array `rangeX`
      this.data[serie].rangeX = this.data[serie].data.map(d => d.x);
    }
    this.updateVis();
  }

  updateVis() {
    const vis = this;

    // Update axes // NOT sure it's useful
    this.xAxisCall.scale(this.x);
    this.xAxis.transition(this.t()).call(this.xAxisCall);
    this.yAxisCall.scale(this.y);
    this.yAxis.transition(this.t()).call(this.yAxisCall);

    // draw the lines
    // eslint-disable-next-line guard-for-in
    for (const serie in this.data) {
      let lineGenerator = line()
        .defined(function(d) {
          return d.y !== null;
        })
        .x(function(d) {
          return vis.x(vis.parserTime(d.x));
        })
        .y(function(d) {
          return vis.y(d.y);
        });

      this.g
        .append("path")
        .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", this.data[serie].color)
        .attr("stroke-width", 1.5)

        .attr("d", lineGenerator(this.data[serie].data));

      if (this.data[serie].isolated && this.data[serie].isolated.length > 0) {
        // draw the isolated values as points
        this.g
          .selectAll(`circle.isolated`)
          .data(this.data[serie].isolated)
          .enter()
          .append("circle")
          .attr("cx", function(d) {
            return vis.x(vis.parserTime(d.x));
          })
          .attr("cy", function(d) {
            return vis.y(d.y);
          })
          .attr("r", 2)
          .attr("fill", this.data[serie].color);
      }
    }

    this.addInteractivity();
  }

  addInteractivity() {
    const vis = this;
    const bisectDate = bisector(function(d) {
      return vis.parserTime(d);
    }).left;

    vis.g
      .append("rect")
      .attr("class", "overlay")
      .attr("fill", "none")
      .style("pointer-events", "all")
      .attr("width", vis.width)
      .attr("height", vis.height)
      .on("mousemove", mousemove);

    function mousemove() {
      try {
        const tooltipElements = document.getElementsByClassName("tooltip");
        while (tooltipElements[0]) {
          tooltipElements[0].parentNode.removeChild(tooltipElements[0]);
        }
      } catch (error) {}

      // Display tooltip
      vis.tooltip = new Tooltip();

      // Remove content from the previous step
      selectAll(".highlighted").remove();

      // calculate the new values
      const xMouse = vis.x.invert(mouse(this)[0]);
      const nearestIndex = bisectDate(vis.rangeX, xMouse);

      const displayTime = vis.rangeX[nearestIndex];
      if (displayTime) {
        // Show vertical line
        vis.g
          .append("line")
          .classed("highlighted", true)
          .attr("x1", vis.x(vis.parserTime(displayTime)))
          .attr("y1", vis.y(vis.y.domain()[1]))
          .attr("x2", vis.x(vis.parserTime(displayTime)))
          .attr("y2", vis.y(vis.y.domain()[0]))
          .attr("stroke", "rgb(31, 51, 73, 0.5)")
          .attr("stroke-width", 1);

        const dataForTooltip = {};
        // show the highlighted points
        for (const serie in vis.data) {
          const indexDate = vis.data[serie].rangeX.indexOf(displayTime);
          if (indexDate !== -1 && vis.data[serie].data[indexDate].y !== null) {
            dataForTooltip[serie] = {
              label: vis.data[serie].label,
              color: vis.data[serie].color,
              data: vis.data[serie].data[indexDate]
            };
            vis.g
              .append("circle")
              .classed("highlighted", true)
              .attr("cx", vis.x(vis.parserTime(displayTime)))
              .attr("cy", vis.y(vis.data[serie].data[indexDate].y))
              .attr("r", 3)
              .attr("stroke", "white")
              .attr("stroke-width", 2)
              .attr("fill", vis.data[serie].color);
          }
        }
        // Show the tooltip
        vis.tooltip.show(displayTime, dataForTooltip);
      }
    }
  }
}

// `identifyIsolatedValues` identifies the isolated
// values (meaning a value that is between two nulls).
// It will be displayed as points on the chart
export const identifyIsolatedValues = serie => {
  const isolated = [];
  if (serie.data.length === 1) {
    return serie.data;
  }
  // if the first element is not null and the second
  // element is null, then the first element is isolated
  const dFirst = serie.data[0];
  if (dFirst && dFirst.y !== null) {
    const next = serie.data[1];
    if (next.y === null) {
      isolated.push(dFirst);
    }
  }
  // Check from the second to the penultimate elements
  for (let i = 1; i < serie.data.length - 1; i++) {
    const d = serie.data[i];
    if (d && d.y !== null) {
      const prev = serie.data[i - 1];
      const next = serie.data[i + 1];
      if (prev.y === null && next.y === null) {
        isolated.push(d);
      }
    }
  }
  // if the last element is not null and the penultimate
  // element is null, then the last element is isolated
  const dLast = serie.data[serie.data.length - 1];
  if (dLast && dLast.y !== null) {
    const prev = serie.data[serie.data.length - 2];
    if (prev.y === null) {
      isolated.push(dLast);
    }
  }
  return isolated;
};

// export only for test
export const getAxisPrecision = diff => {
  let r;
  if (diff === 0) {
    r = "d";
  } else if (diff <= 0.0001) {
    r = ".5f";
  } else if (diff <= 0.001) {
    r = ".4f";
  } else if (diff <= 0.01) {
    r = ".3f";
  } else if (diff <= 0.1) {
    r = ".2f";
  } else if (diff < 1) {
    r = ".1f";
  } else {
    r = "d";
  }
  return r;
};
